import { Button, Flex, Grid, Stack, Text } from '@mantine/core'
import React, { useEffect } from 'react'
import useSWR from 'swr'
import CountryInput from './inputs/country_input'

export const hitsCountries = [
  'ID',
  'AR',
  'IT',
  'PE',
  'GB',
  'KR',
  'TH',
  'CO',
  'TR',
  'US',
  'CA',
  'FR',
  'BR',
  'DE',
  'ES',
  'MX',
  'AU',
  'CL',
  'IN',
  'BE',
  'PH',
  'SE',
  'DK',
  'NO',
  'ZA',
  'NL'
]

export const CpCountries = [
  'AE',
  'AR',
  'AT',
  'AU',
  'BD',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CL',
  'CO',
  'CR',
  'CZ',
  'DE',
  'DK',
  'EC',
  'EG',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'GT',
  'HK',
  'HN',
  'HR',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IT',
  'JP',
  'KR',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NG',
  'NI',
  'NL',
  'NO',
  'NZ',
  'PA',
  'PE',
  'PH',
  'PL',
  'PT',
  'RO',
  'SA',
  'SE',
  'SG',
  'SV',
  'TH',
  'TR',
  'TW',
  'UA',
  'US',
  'UY',
  'VE',
  'VN',
  'ZA'
]

/* const revenueModelMap = {
  'subscription_vod': 'SVOD',
  'tv_everywhere': 'TVE',
  'transaction_vod': 'TVOD',
  'free_vod': 'FVOD',
  'Advertising VOD': 'AVOD',
  'validated_vod': 'VVOD'
}*/

const CountryDetail = ({ label, value }) => (
  <Flex align="center">
    <Text inline c="strong" mr={5} style={{ fontWeight: 'bold' }}>
      {label.toUpperCase()}:
    </Text>
    <Text c="dimmed">{value}</Text>
  </Flex>
)

const fetcher = (url) => fetch(url).then((res) => res.json())

const CountrySelectorDetails = ({
  country,
  setCountry,
  onLiveStreamingChange,
  id,
  onStatusAvailable // Nueva prop
}) => {
  // API connect to use in COUNTRY SELECTOR
  const { data: selectedCountryData } = useSWR(
    `/api/api_ext/platforms/${id}/${country}`,
    fetcher
  )

  const liveStreamingValue = selectedCountryData?.has_live_streaming ? 'YES' : 'NO'

  useEffect(() => {
    if (onLiveStreamingChange) {
      onLiveStreamingChange(liveStreamingValue)
    }

    // Verificar el estado y llamar a la prop onStatusAvailable si está disponible
    if (selectedCountryData?.status === 'available' && onStatusAvailable) {
      onStatusAvailable(true)
    } else if (onStatusAvailable) {
      onStatusAvailable(false)
    }
  }, [
    liveStreamingValue,
    onLiveStreamingChange,
    selectedCountryData,
    onStatusAvailable
  ])

  return (
    <Grid.Col span={4} pl={100} pr={30}>
      <CountryInput
        w={'100%'}
        withLabel={false}
        onChange={setCountry}
        value={country}
        filterCountries={hitsCountries}
      />
      <Stack
        mt={20}
        w={'100%'}
        sx={{
          gap: '0',
          color: 'white',
          fontSize: '12px',
          backgroundColor: '#0E1922',
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        }}
      >
        <CountryDetail
          label="Launched"
          value={selectedCountryData?.launch_year || 'N/A'}
          style={{ marginTop: '20px' }}
        />
        {/* <CountryDetail
          label="Revenue Model"
          value={
            Object.entries(selectedCountryData?.content_qty || {})
              .reduce((acc, [key, value]) => {
                if (value > 0) {
                  acc.push(revenueModelMap[key] || key)
                }
                return acc
              }, [])
              .join(', ') || 'N/A'
          }
        /> */}
        <CountryDetail
          label="Have Original"
          value={selectedCountryData?.has_originals ? 'YES' : 'NO'}
        />

        <CountryDetail label="Live Streaming" value={liveStreamingValue} />

        <CountryDetail
          label="Content Aggregator"
          value={selectedCountryData?.is_active ? 'YES' : 'NO'}
        />
        <CountryDetail
          label="Category"
          value={selectedCountryData?.category || 'Miscellaneous'}
        />
      </Stack>

      <Button
        sx={{
          backgroundColor: '#7B39B6',
          color: '#ffffff',
          borderRadius: '5px',
          height: '30px',
          width: '100%',
          fontSize: '11px',
          fontWeight: 'bolder',
          '&:hover': {
            backgroundColor: '#7B39B6'
          },
          marginTop: '10px'
        }}
      >
        CONTACT US
      </Button>
    </Grid.Col>
  )
}

export default CountrySelectorDetails
