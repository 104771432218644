import { Box, Group, Select, Text } from '@mantine/core'
import { IconWorld } from '@tabler/icons'
import Image from 'next/image'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { useLocaleContext } from '../../context/locale'
import countries from '../../utils/countries.json'
import { CpCountries } from '../platform_details.js'

export const hitsCountries = [
  'ID',
  'AR',
  'IT',
  'PE',
  'GB',
  'KR',
  'TH',
  'CO',
  'TR',
  'US',
  'CA',
  'FR',
  'BR',
  'DE',
  'ES',
  'MX',
  'AU',
  'CL',
  'IN',
  'BE',
  'PH',
  'SE',
  'DK',
  'NO',
  'ZA',
  'NL'
]

const data = countries
  .map((c) => ({
    label: c.name,
    value: c.code.toLowerCase(),
    flag: `https://flagcdn.com/16x12/${c.code.toLowerCase()}.png`
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

const SelectItem = forwardRef(({ label, flag, ...others }, ref) => (
  <Box ref={ref} {...others}>
    <Group align="baseline">
      <Image src={flag} alt={label} width={16} height={12} />
      <Text>{label}</Text>
    </Group>
  </Box>
))

SelectItem.displayName = 'SelectItem'
SelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired
}

const CountryInput = ({
  withLabel = true,
  onChange,
  value,
  filterCountries,
  uppercase = false,
  ...other
}) => {
  const { country, setCountry } = useLocaleContext()

  const filteredData = filterCountries
    ? data.filter((item) => filterCountries.includes(item.value.toUpperCase()))
    : data.filter((item) => CpCountries.includes(item.value.toUpperCase()))

  return (
    <Select
      searchable
      icon={<IconWorld size={20} />}
      placeholder="Pick one"
      nothingFound="No options"
      data={filteredData}
      itemComponent={SelectItem}
      value={value || country}
      onChange={onChange || setCountry}
      {...other}
      dropdownPosition="bottom"
      withinPortal
      styles={(theme) => ({
        input: {
          fontSize: theme.fontSizes.sm,
          backgroundColor: '#121E274D',
          padding: '4px 8px',
          borderRadius: '10px',
          border: 'none',
          borderBottom: '2px solid #7B39B6',
          opacity: 1,
          position: 'relative',
          ...(uppercase && {
            textTransform: 'uppercase',
            fontWeight: 100,
            fontSize: '16.5px'
          })
        },
        dropdown: {
          zIndex: 1000
        },
        item: {
          fontSize: theme.fontSizes.xs
        }
      })}
    />
  )
}

CountryInput.propTypes = {
  withLabel: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  filterCountries: PropTypes.arrayOf(PropTypes.string),
  uppercase: PropTypes.bool,
  other: PropTypes.object
}

export default CountryInput
