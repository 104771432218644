import {
  Box,
  Burger,
  Drawer,
  Flex,
  Group,
  Header,
  ScrollArea,
  TextInput
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useAppContext } from '../../context/app'
import SearchInput from '../inputs/search_input'
import LoggedInMenu from './logged_in_menu'
import LoggedOutMenu from './logged_out_menu'

const MyNavbar = ({ withAutocomplete, showSearchBar }) => {
  const { urlQuery, setUrlQuery } = useAppContext()
  const { data: session } = useSession()
  const [opened, setOpened] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1200px)')
  const t = useTranslations('Navbar')
  const { locale } = useRouter()

  const toggleDrawer = () => setOpened((opened) => !opened)

  return (
    <Header
      height={isMobile ? '7rem' : '4rem'}
      px={isMobile ? 'md' : 'xl'}
      withBorder={false}
      sx={{ backgroundColor: 'transparent', position: 'absolute', width: '100%' }}
    >
      <Group position="apart" align="center" sx={{ height: '100%' }}>
        {isMobile ? (
          <Flex
            direction="column"
            align="center"
            justify="center"
            sx={{ width: '100%', maxWidth: '1366px', margin: 'auto' }}
          >
            <Flex align="center" justify="space-between" sx={{ width: '100%' }}>
              <Burger
                opened={opened}
                onClick={toggleDrawer}
                aria-label={
                  opened ? 'Close navigation menu' : 'Open navigation menu'
                }
              />
              <Link
                href="/"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  height: '23px',
                  width: '180px'
                }}
              >
                <Image
                  src="/stream_discover_logo.png"
                  fill
                  alt="StreamDiscover logo"
                />
              </Link>
              <Box>{session ? <LoggedInMenu /> : <LoggedOutMenu />}</Box>
            </Flex>

            {showSearchBar && (
              <Box
                mt="xs"
                sx={{
                  width: isMobile ? 'calc(100% - 1rem)' : 'calc(40% - 1rem)',
                  maxWidth: '28rem',
                  marginLeft: '1.5rem'
                }}
              >
                {withAutocomplete ? (
                  <SearchInput />
                ) : (
                  <TextInput
                    icon={<IconSearch size={18} stroke={1.5} />}
                    value={urlQuery}
                    onChange={(e) => setUrlQuery(e.currentTarget.value)}
                    sx={{ width: '100%', fontSize: '1rem', padding: '0.625rem' }}
                  />
                )}
              </Box>
            )}
          </Flex>
        ) : (
          <Flex
            justify="space-between"
            align="center"
            sx={{
              width: '80vw',
              maxWidth: '1366px',
              margin: 'auto'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                marginRight: '1.5rem',
                maxWidth: '180px',
                minWidth: '180px'
              }}
            >
              <Link
                href="/"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  height: '23px',
                  width: '180px'
                }}
              >
                <Image
                  src="/stream_discover_logo.png"
                  fill
                  alt="StreamDiscover logo"
                />
              </Link>
            </Box>

            {showSearchBar && (
              <Box sx={{ width: '40%', maxWidth: '28rem', marginLeft: '1.5rem' }}>
                {withAutocomplete ? (
                  <SearchInput />
                ) : (
                  <TextInput
                    width="100%"
                    icon={<IconSearch size={18} stroke={1.5} />}
                    value={urlQuery}
                    onChange={(e) => setUrlQuery(e.currentTarget.value)}
                    sx={{ fontSize: '1rem', padding: '0.625rem' }}
                  />
                )}
              </Box>
            )}

            <Flex
              align="center"
              gap="3.5rem"
              justify="center"
              sx={{ paddingLeft: '1.5rem' }}
            >
              <Link
                href="/services"
                locale={locale}
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  color: '#7B39B6',
                  whiteSpace: 'nowrap'
                }}
              >
                {t('streamingServices')}
              </Link>
              <Link
                href="/contents"
                locale={locale}
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  color: '#7B39B6',
                  whiteSpace: 'nowrap'
                }}
              >
                {t('contents')}
              </Link>
              <Link
                href="/coming-soon"
                locale={locale}
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  color: '#7B39B6',
                  whiteSpace: 'nowrap'
                }}
              >
                {t('comingSoon')}
              </Link>

              <Box>{session ? <LoggedInMenu /> : <LoggedOutMenu />}</Box>
            </Flex>
          </Flex>
        )}

        <Drawer
          opened={opened}
          onClose={toggleDrawer}
          position="left"
          size="56%"
          padding="xs"
          withCloseButton
        >
          <ScrollArea style={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
                padding: '1rem'
              }}
            >
              <Link href="/hits" locale={locale}>
                {t('hits')}
              </Link>
              <Link href="/services" locale={locale}>
                {t('streamingServices')}
              </Link>
              <Link href="/contents" locale={locale}>
                {t('contents')}
              </Link>
              <Link href="/coming-soon" locale={locale}>
                {t('comingSoon')}
              </Link>
              <Link href="/pricing" locale={locale}>
                {t('pricing')}
              </Link>
            </Box>
          </ScrollArea>
        </Drawer>
      </Group>
    </Header>
  )
}

MyNavbar.propTypes = {
  withAutocomplete: PropTypes.bool,
  showSearchBar: PropTypes.bool
}

export default MyNavbar
