import { Avatar, Button, Menu, Modal, Stack } from '@mantine/core'
import { IconSettings, IconUser } from '@tabler/icons'
import Cookies from 'js-cookie'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import CountryInput from '../inputs/country_input'
import LanguageInput from '../inputs/language_input.js'
import { CpCountries } from '../platform_details.js'

const locales = {
  ID: [
    { label: 'Bahasa Indonesia', value: 'id' },
    { label: 'English', value: 'en' }
  ], // Indonesia
  AR: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Argentina
  IT: [
    { label: 'Italiano', value: 'it' },
    { label: 'English', value: 'en' }
  ], // Italy
  PE: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Peru
  GB: [{ label: 'English', value: 'en' }], // United Kingdom
  KR: [
    { label: '한국어', value: 'ko' },
    { label: 'English', value: 'en' }
  ], // South Korea
  TH: [
    { label: 'ไทย', value: 'th' },
    { label: 'English', value: 'en' }
  ], // Thailand
  CO: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Colombia
  TR: [
    { label: 'Türkçe', value: 'tr' },
    { label: 'English', value: 'en' }
  ], // Turkey
  US: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // United States (English and Spanish)
  CA: [
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' }
  ], // Canada (English and French)
  FR: [
    { label: 'Français', value: 'fr' },
    { label: 'English', value: 'en' }
  ], // France
  BR: [
    { label: 'Português', value: 'pt' },
    { label: 'English', value: 'en' }
  ], // Brazil
  DE: [
    { label: 'Deutsch', value: 'de' },
    { label: 'English', value: 'en' }
  ], // Germany
  ES: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Spain
  MX: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Mexico
  AU: [{ label: 'English', value: 'en' }], // Australia
  CL: [
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' }
  ], // Chile
  IN: [
    { label: 'हिन्दी', value: 'hi' },
    { label: 'English', value: 'en' }
  ], // India (Hindi and English)
  BE: [
    { label: 'Français', value: 'fr' },
    { label: 'English', value: 'en' }
  ], // Belgium (French and English)
  PH: [{ label: 'English', value: 'en' }], // Philippines (English)
  SE: [{ label: 'English', value: 'en' }], // Sweden (English)
  DK: [{ label: 'English', value: 'en' }], // Denmark (English)
  NO: [{ label: 'English', value: 'en' }], // Norway (English)
  ZA: [{ label: 'English', value: 'en' }], // South Africa (English)
  NL: [{ label: 'English', value: 'en' }], // Netherlands (English)
  AE: [{ label: 'English', value: 'en' }], // United Arab Emirates
  AT: [{ label: 'English', value: 'en' }], // Austria
  BD: [{ label: 'English', value: 'en' }], // Bangladesh
  BG: [{ label: 'English', value: 'en' }], // Bulgaria
  CH: [{ label: 'English', value: 'en' }], // Switzerland
  CR: [{ label: 'English', value: 'en' }], // Costa Rica
  CZ: [{ label: 'English', value: 'en' }], // Czech Republic
  EC: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // Ecuador
  EG: [{ label: 'English', value: 'en' }], // Egypt
  FI: [{ label: 'English', value: 'en' }], // Finland
  GR: [{ label: 'English', value: 'en' }], // Greece
  GT: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // Guatemala
  HK: [{ label: 'English', value: 'en' }], // Hong Kong
  HN: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // Honduras
  HR: [{ label: 'English', value: 'en' }], // Croatia
  HU: [{ label: 'English', value: 'en' }], // Hungary
  IE: [{ label: 'English', value: 'en' }], // Ireland
  IL: [{ label: 'English', value: 'en' }], // Israel
  JP: [{ label: 'English', value: 'en' }], // Japan
  LT: [{ label: 'English', value: 'en' }], // Lithuania
  LU: [{ label: 'English', value: 'en' }], // Luxembourg
  LV: [{ label: 'English', value: 'en' }], // Latvia
  MT: [{ label: 'English', value: 'en' }], // Malta
  MY: [{ label: 'English', value: 'en' }], // Malaysia
  NG: [{ label: 'English', value: 'en' }], // Nigeria
  NI: [{ label: 'English', value: 'en' }], // Nicaragua
  NZ: [{ label: 'English', value: 'en' }], // New Zealand
  PA: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // Panama
  PL: [{ label: 'English', value: 'en' }], // Poland
  PT: [{ label: 'English', value: 'en' }], // Portugal
  RO: [{ label: 'English', value: 'en' }], // Romania
  SA: [{ label: 'English', value: 'en' }], // Saudi Arabia
  SG: [{ label: 'English', value: 'en' }], // Singapore
  SV: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // El Salvador
  TW: [{ label: 'English', value: 'en' }], // Taiwan
  UA: [{ label: 'English', value: 'en' }], // Ukraine
  UY: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // Uruguay
  VE: [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ], // Venezuela
  VN: [{ label: 'English', value: 'en' }] // Vietnam
}

const LoggedOutMenu = () => {
  const [modalOpened, setModalOpened] = useState(false)
  const router = useRouter()
  const t = useTranslations('Navbar')
  const country = router.locale?.split('-')[1].toLowerCase()
  const language = router.locale?.split('-')[0]
  const [selectedCountry, setSelectedCountry] = useState(country)
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    // Si el idioma ya existente en el locale no está en la lista de idiomas del país, selecciona el primero
    const currentLanguages = locales[country.toUpperCase()].map((l) => l.value)
    return currentLanguages.includes(language) ? language : currentLanguages[0]
  })
  const [selectedPlatforms, setSelectedPlatforms] = useState([])

  const hitsCountries = [
    'ID',
    'AR',
    'IT',
    'PE',
    'GB',
    'KR',
    'TH',
    'CO',
    'TR',
    'US',
    'CA',
    'FR',
    'BR',
    'DE',
    'ES',
    'MX',
    'AU',
    'CL',
    'IN',
    'BE',
    'PH',
    'SE',
    'DK',
    'NO',
    'ZA',
    'NL'
  ]

  const platforms = [
    { value: 'Netflix', label: 'Netflix' },
    { value: 'Disney', label: 'Disney' },
    { value: 'Hulu', label: 'Hulu' },
    { value: 'Max', label: 'Max' },
    { value: 'Amazon Prime Video', label: 'Amazon Prime Video' }
  ]

  useEffect(() => {
    // Cargar país seleccionado del contexto
    setSelectedCountry(country)

    // Cargar plataformas guardadas en localStorage
    const storedPlatforms = localStorage.getItem('selectedPlatforms')
    if (storedPlatforms) {
      setSelectedPlatforms(JSON.parse(storedPlatforms))
    }
  }, [country])

  useEffect(() => {
    const currentLanguages = locales[selectedCountry.toUpperCase()].map(
      (l) => l.value
    )
    setSelectedLanguage((prev) => {
      return currentLanguages.includes(prev) ? prev : currentLanguages[0]
    })
  }, [selectedCountry])

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry)
  }

  const handlePlatformChange = (selected) => {
    setSelectedPlatforms(selected)
    localStorage.setItem('selectedPlatforms', JSON.stringify(selected))
  }

  const onClick = () => {
    setModalOpened(false)
    const newLocale = `${selectedLanguage}-${selectedCountry.toUpperCase()}`
    const consent = Cookies.get('cookie_consent') // Assuming you have saved consent here

    if (consent) {
      const consentObj = JSON.parse(consent) // Parse the stored JSON consent object

      // Only set the locale cookie if the user has consented to functional cookies
      if (consentObj.functional) {
        document.cookie = `NEXT_LOCALE=${newLocale}; path=/; max-age=31536000`
      }
    }
    if (router.locale !== newLocale) {
      router.push(router.asPath, router.asPath, { locale: newLocale })
    }
  }

  return (
    <>
      <Menu width={200}>
        <Menu.Target>
          <Avatar
            variant="gradient"
            radius="xl"
            src="/icon_user.png"
            size={35}
            sx={{
              cursor: 'pointer',
              transition: 'transform 0.3s ease, opacity 0.3s ease',
              '&:hover': {
                opacity: 0.8,
                transform: 'scale(1.1)' // Aumenta el tamaño al pasar el mouse
              }
            }}
          ></Avatar>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item disabled>{t('welcome')}</Menu.Item>
          <Menu.Divider />
          <Link href="/login" passHref>
            <Menu.Item component="a" icon={<IconUser size={14} />}>
              {t('login')}
            </Menu.Item>
          </Link>
          <Menu.Item
            icon={<IconSettings size={14} />}
            onClick={() => setModalOpened(true)}
          >
            {t('settings')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={t('settings')}
        centered
        size="md"
      >
        <Stack spacing={24} align="center">
          <CountryInput
            sx={{
              width: '80%',
              fontSize: '10px'
            }}
            label={t('country')}
            onChange={handleCountryChange}
            value={selectedCountry}
            filterCountries={CpCountries}
          />
          <LanguageInput
            language={selectedLanguage}
            label={t('language')}
            setLanguage={setSelectedLanguage}
            options={locales[selectedCountry.toUpperCase()] || []}
            sx={{
              width: '80%'
            }}
          />
          {/* <PlatformInput
            platforms={platforms}
            value={selectedPlatforms}
            onChange={handlePlatformChange}
            label={t('platforms')}
          /> */}
          <Button sx={{ backgroundColor: '#7B39B6' }} w={'80%'} onClick={onClick}>
            {t('save')}
          </Button>
        </Stack>
      </Modal>
    </>
  )
}

export default LoggedOutMenu
